<template>
  <div class="contact">
    <div class="location">
      <div id="map"></div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    var map = new BMap.Map("map");
    // 创建地图实例
    var point = new BMap.Point(117.212832, 31.8373);
    // 创建点坐标
    map.centerAndZoom(point, 18);
    // 初始化地图，设置中心点坐标和地图级别
    // map.enableScrollWheelZoom();
    map.disableDragging();

    var myIcon = new BMap.Icon(require("@/assets/images/location.png"), new BMap.Size(24, 35));

    var marker = new BMap.Marker(point, {
      icon: myIcon,
      offset: new BMap.Size(0, -10)
    });
    marker.addEventListener("click", () => {
      map.openInfoWindow(infoWindow, point); //开启信息窗口
    });
    map.addOverlay(marker); // 增加点

    var opts = {
      offset: new BMap.Size(0, -25),
      width: 350, // 信息窗口宽度
      // height: 100, // 信息窗口高度
      title: `<div class="comp_name">安徽碧耕软件有限公司</div>` // 信息窗口标题
    };
    var infoWindow = new BMap.InfoWindow(
      `<div class="comp_location">地址：合肥市高新区同创科技园6栋2楼</div><div class="comp_phone">售后服务电话：0551-6367-1869</div>`,
      opts
    ); // 创建信息窗口对象
    map.openInfoWindow(infoWindow, point); //开启信息窗口
  },
};
</script>

<style lang="scss" scoped>
.location {
  #map {
    height: 760px;
  }
  ::v-deep {
    .amap-info-close {
      top: 33px;
    }
    .info_window {
      width: 400px;
      padding: 14px;
    }
    .comp_name {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      opacity: 1;
      margin-bottom: 24px;
    }
    .comp_location,
    .comp_phone {
      font-size: 19px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 32px;
      color: #666666;
      opacity: 1;
    }
  }
}
</style>